import React, { Suspense, useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import "./App.css";
import LoadingSpinner from "./shared/UI/LoadingSpinner";
import { loginActions } from "./store/login-slice";

const InvitationForm = React.lazy(() =>
  import("./guests/pages/InvitationForm/InvitationForm")
);
const Home = React.lazy(() => import("./web/pages/Home/Home"));
const Login = React.lazy(() => import("./user/pages/Login/Login"));
const Dashboard = React.lazy(() => import("./user/pages/Dashboard/Dashboard"));
const GuestList = React.lazy(() => import("./user/pages/GuestList/GuestList"));
const Feedback = React.lazy(() => import("./guests/pages/Feedback/Feedback"));
const NotFound = React.lazy(() => import("./ui/Error/NotFound"));

let logoutTimer;

function App() {
  const token = useSelector((state) => state.login.token);
  const tokenExpirationDate = useSelector(
    (state) => state.login.tokenExpirationDate
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("userData"));

    if (
      storedData &&
      storedData.token &&
      new Date(storedData.expiration) > new Date()
    ) {
      dispatch(
        loginActions.login({
          userId: storedData.userId,
          token: storedData.token,
          expiration: new Date(storedData.expiration).toISOString(),
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    if (token && tokenExpirationDate) {
      const remainingTime =
        new Date(tokenExpirationDate).getTime() - new Date().getTime();

      logoutTimer = setTimeout(() => {
        dispatch(loginActions.logout);
      }, remainingTime);
    } else {
      clearTimeout(logoutTimer);
    }
  }, [token, tokenExpirationDate, dispatch]);

  let routes;
  let commonRoutes = (
    <React.Fragment>
      <Route path="/rsvp" element={<InvitationForm />} />
      <Route path="/feedback" element={<Feedback />} />
      <Route path="/" exact element={<Home />} />
      <Route path="*" element={<NotFound />} />
    </React.Fragment>
  );

  if (token) {
    routes = (
      <Routes>
        <Route
          path="/admin"
          element={<Navigate replace={true} to="/dashboard" />}
        />
        <Route path="/dashboard" element={<Dashboard />}>
          <Route
            index
            element={<Navigate replace={true} to="/dashboard/guest-list" />}
          />
          <Route path="/dashboard/guest-list" exact element={<GuestList />} />
          <Route path="/dashboard/*" element={<NotFound />} />
        </Route>
        {commonRoutes}
      </Routes>
    );
  } else {
    routes = (
      <Routes>
        <Route path="/admin" element={<Login />} />
        <Route
          path="/dashboard"
          element={<Navigate replace={true} to="/admin" />}
        >
          <Route
            path="/dashboard/*"
            element={<Navigate replace={true} to="/admin" />}
          />
        </Route>
        {commonRoutes}
      </Routes>
    );
  }

  return (
    <main className="App">
      <Suspense fallback={<LoadingSpinner text="Loading..."></LoadingSpinner>}>
        {/* <ul>
          <li>
            <Link to="/admin">Login</Link>
          </li>
          <li>
            <Link to="/add-guest">Add Guests</Link>
          </li>
          <li>
            <Link to="/rsvp">Invitation Form</Link>
          </li>
        </ul> */}
        {routes}
      </Suspense>
    </main>
  );
}

export default App;
