import { configureStore } from "@reduxjs/toolkit";

import uiSlice from "./ui-slice";
import dashBoardSlice from "./dashboard-slice";
import loginSlice from "./login-slice";
import guestSlice from "./guest-slice";

const store = configureStore({
  reducer: {
    ui: uiSlice.reducer,
    dashboard: dashBoardSlice.reducer,
    login: loginSlice.reducer,
    guest: guestSlice.reducer,
  },
});

export default store;
