import { createSlice } from "@reduxjs/toolkit";

const dashBoardSlice = createSlice({
  name: " dashboard",
  initialState: { guestList: [] },
  reducers: {
    setGuest: (state, action) => {
      state.guestList = action.payload.guestList.sort((guestA, guestB) => {
        return guestA.name > guestB.name ? 1 : -1;
      });
    },
  },
});

export const dashboardActions = dashBoardSlice.actions;

export default dashBoardSlice;
