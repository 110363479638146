import React from "react";

import "./LoadingSpinner.css";

const LoadingSpinner = (props) => {
  return (
    <div className="mx-auto my-[120px] max-w-sm flex justify-center items-center">
      <span className="loader"></span>
      <span className="inline-block ml-8">{props.text}</span>
    </div>
  );
};

export default LoadingSpinner;
