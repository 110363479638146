import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

const guestSlice = createSlice({
  name: "guest",
  initialState: {
    guestData: {
      _id: null,
      isattending: "Yes",
      name: "",
      contact: "",
      email: "",
      address: "",
      dietaryrestrictions: {
        vegan: {
          value: false,
        },
        halal: {
          value: false,
        },
        nobeef: {
          value: false,
        },
        others: {
          enabled: false,
          value: "",
        },
      },
      requirements: "",
      parkingcoupon: "No",
      additionalGuests: [],
    },
    guestId: "",
    hasGuestId: false,
    guestDataHasChanged: false,
    fetchingError: false,
    updateSuccess: false,
  },
  reducers: {
    setGuestData: (state, action) => {
      state.guestData = action.payload.guest;

      if (typeof state.guestData.additionalGuests === "undefined") {
        state.guestData.additionalGuests = [];
      }

      state.hasGuestId = true;
      state.guestId = action.payload.guestId;
    },
    setFetchError: (state) => {
      state.fetchingError = true;
    },
    resetGuestData: (state) => {
      state.guestData = {
        _id: null,
        isattending: "Yes",
        name: "",
        contact: "",
        email: "",
        address: "",
        dietaryrestrictions: {
          vegan: {
            value: false,
          },
          halal: {
            value: false,
          },
          nobeef: {
            value: false,
          },
          others: {
            enabled: false,
            value: "",
          },
        },
        requirements: "",
        parkingcoupon: "No",
        additionalGuests: [],
      };
      state.hasGuestId = false;
      state.guestId = "";
      state.guestDataHasChanged = false;
    },
    setGuestDataHasChanged: (state, action) => {
      state.guestDataHasChanged = action.payload.value;
    },
    addNewGuest: (state) => {
      state.guestDataHasChanged = true;

      const newGuest = {
        id: uuidv4(),
        name: "",
        dietaryrestrictions: {
          vegan: {
            value: false,
          },
          halal: {
            value: false,
          },
          nobeef: {
            value: false,
          },
          others: {
            enabled: false,
            value: "",
          },
        },
      };

      const newAdditionalGuests = [
        ...state.guestData.additionalGuests,
        newGuest,
      ];
      state.guestData.additionalGuests = newAdditionalGuests;
    },
    removeGuest: (state, action) => {
      state.guestDataHasChanged = true;

      const currentAdditionalGuestList = [...state.guestData.additionalGuests];

      const removedGuestList = currentAdditionalGuestList.filter((guest) => {
        if (guest._id) {
          return guest._id !== action.payload.guestId;
        } else {
          return guest.id !== action.payload.guestId;
        }
      });

      state.guestData.additionalGuests = removedGuestList;
    },
    mainGuestPropertyChange: (state, action) => {
      state.guestDataHasChanged = true;

      state.guestData[action.payload.property] = action.payload.value;
    },
    mainGuestDietaryRestrictionsChange: (state, action) => {
      state.guestDataHasChanged = true;

      state.guestData.dietaryrestrictions[action.payload.name].value =
        action.payload.value;
    },
    mainGuestToggleOthers: (state) => {
      state.guestDataHasChanged = true;

      state.guestData.dietaryrestrictions.others.enabled =
        !state.guestData.dietaryrestrictions.others.enabled;
    },
    additionalGuestPropertyChange: (state, action) => {
      state.guestDataHasChanged = true;

      state.guestData.additionalGuests[action.payload.i][
        action.payload.property
      ] = action.payload.value;
    },
    additionalGuestDietaryRestrictionsChange: (state, action) => {
      state.guestDataHasChanged = true;

      state.guestData.additionalGuests[action.payload.i].dietaryrestrictions[
        action.payload.name
      ].value = action.payload.value;
    },
    additionalGuestToggleOthers: (state, action) => {
      state.guestDataHasChanged = true;

      state.guestData.additionalGuests[
        action.payload.i
      ].dietaryrestrictions.others.enabled =
        !state.guestData.additionalGuests[action.payload.i].dietaryrestrictions
          .others.enabled;
    },
    guestIdChange: (state, action) => {
      state.guestId = action.payload.value;
    },
    updateGuestData: (state, action) => {
      if (action.payload.status === 200) {
        state.updateSuccess = true;
      } else {
        state.updateSuccess = false;
      }
    },
  },
});

export const guestActions = guestSlice.actions;

export default guestSlice;
