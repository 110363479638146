import { createSlice } from "@reduxjs/toolkit";

const uiSlice = createSlice({
  name: "ui",
  initialState: {
    isLoading: false,
    notification: {
      title: "",
      message: "",
      success: null,
      show: false,
    },
  },
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload.value;
    },
    setNotification: (state, action) => {
      state.notification.title = action.payload.title;
      state.notification.message = action.payload.message;
      state.notification.success = action.payload.success;
      state.notification.show = action.payload.show;
    },
    closeNotification: (state) => {
      state.notification.show = false;
    },
  },
});

export const uiActions = uiSlice.actions;

export default uiSlice;
