import { createSlice } from "@reduxjs/toolkit";

const loginSlice = createSlice({
  name: "login",
  initialState: { userId: null, token: null, tokenExpirationDate: null },
  reducers: {
    login(state, action) {
      state.userId = action.payload.userId;
      state.token = action.payload.token;

      const tokenExpiry = action.payload.expiration;
      state.tokenExpirationDate = tokenExpiry;

      localStorage.setItem(
        "userData",
        JSON.stringify({
          userId: action.payload.userId,
          token: action.payload.token,
          expiration: tokenExpiry,
        })
      );
    },
    logout(state) {
      state.userId = null;
      state.token = null;
      state.tokenExpirationDate = null;
      localStorage.removeItem("userData");
    },
  },
});

export const loginActions = loginSlice.actions;

export default loginSlice;
